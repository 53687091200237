<template>
    <uw-content title="考核档案" padding="10px">

        <!-- 分类 -->
        <template slot="prefix">
            <el-button :type="search.state == null ? 'primary' : 'text'" @click="search.state = null; TableSearch()">全部</el-button>
            <el-button :type="search.state == 0 ? 'primary' : 'text'" @click="search.state = 0; TableSearch()">未开始</el-button>
            <el-button :type="search.state == 1 ? 'primary' : 'text'" @click="search.state = 1; TableSearch()">进行中</el-button>
            <el-button :type="search.state == 2 ? 'primary' : 'text'" @click="search.state = 2; TableSearch()">已归档</el-button>
        </template>

        <!-- 表格 -->
        <uw-table :show-header="false">

            <!-- 表数据 -->
            <template slot="data">
                <!-- 表内容 -->
                <vxe-table ref="xTable" :data="table" :loading="loading" height="100%">

                    <!-- 状态 -->
                    <vxe-column width="80" title="状态" align="center">
                        <template #default="{ row }">
                            <el-tag effect="dark" v-if="row.state == 0" type="warning">未开始</el-tag>
                            <el-tag effect="dark" v-if="row.state == 1">进行中</el-tag>
                            <el-tag effect="dark" v-if="row.state == 2">评审完成</el-tag>
                            <el-tag effect="dark" v-if="row.state == 3">已归档</el-tag>
                            <el-tag effect="dark" v-if="row.state == 4" type="danger">有争议</el-tag>
                        </template>
                    </vxe-column>

                    <!-- 考核计划 -->
                    <vxe-column min-width="180" title="考核计划">
                        <template #default="{ row }">
                            <span class="el-span-primary" @click="$refs.planDrawer.Open(row.plan.id)">{{ row.plan?.name }}</span>
                        </template>
                    </vxe-column>

                    <vxe-column min-width="160" title="考核周期">
                        <template #default="{ row }">
                            {{ row.plan.date.join(' 至 ') }}
                        </template>
                    </vxe-column>

                    <!-- 被考核人 -->
                    <vxe-column min-width="120" title="被考核人">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.users?.avatar"> {{ row.users?.name[0] }} </el-avatar>
                                {{  row.users?.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 评审员 -->
                    <vxe-column min-width="120" title="考核官">
                        <template #default="{ row }">
                            <el-avatar v-for="(e, i) in row.flow_all_users" :key="i" style="margin-right: 5px;" :size="26" :src="e?.avatar"> {{ e?.name[0] }} </el-avatar>
                        </template>
                    </vxe-column>

                    <!-- 考核结果 -->
                    <vxe-column min-width="160" field="result_score" title="最终得分"></vxe-column>
                    <vxe-column min-width="160" field="result_level" title="最终评级"></vxe-column>

                    <!-- 操作 -->
                    <vxe-column width="100"  title="操作" align="center" fixed="right">
                        <template #default="{ row }">
                            <span class="el-span-primary" @click="$refs.dossierDrawer.Open(row.id)">考核/记录</span>
                        </template>
                    </vxe-column>

                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>
            
        </uw-table>

        <!-- 组件 -->
        <hrm-kpi-plan-drawer ref="planDrawer" @onChange="TableSearch()" />
        <hrm-kpi-dossier-drawer ref="dossierDrawer" @onChange="TableSearch()" />
        
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 数据
            table: [],

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                state: null,
                users_id: this.$store.state.user.id
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    computed: {
        loading () {
            return !!this.$store.state.httpRequestCount
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        // 查询
        TableSearch()
        {
            this.$http.post(
                '/9api/hrm/kpi-dossier/search',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    append: ['plan', 'users', 'flow_all_users'],
                    hidden: ['t_class', 't_flow', 't_result']
                }
            ).then((rps) => {

                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }
            })
        }
    }
}
</script>